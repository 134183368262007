<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="@/assets/image/phone.jpg"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <p class="subheading font-weight-regular">
          Cảm ơn bạn đã xác thực số điện thoại thành công
          <br />
          Truy cập vào
          <router-link to="/" style="text-decoration: none;">trang chủ</router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "p404",
};
</script>
